import {Injectable} from '@angular/core';
import {httpConfig, HttpService} from '../http-service/http.service';
import {ApiUrlConstants} from '@coreconstant';

@Injectable({
    providedIn: 'root'
})
export class TerritoryService {
    baseUrlApi = ApiUrlConstants.TERRITORY_BASE_URL;
    httpConfig: httpConfig;

    constructor(private _httpService: HttpService) {
    }

    /**
     * Get Territory Lists
     * @param input
     * @returns
     */
    getTerritoryList() {
        this.httpConfig = {
            url: this.baseUrlApi,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get Territory Details
     * @param territorySlug
     * @param input
     * @returns
     */
    getTerritoryDetails(territorySlug, requestParams) {
        this.httpConfig = {
            url: this.baseUrlApi + '/' + territorySlug,
            method: 'get',
            filterParameters: requestParams,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }
}

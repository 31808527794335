import {Injectable} from '@angular/core';
import {ApiUrlConstants} from '../../constants/api-url-constants';
import {httpConfig, HttpService} from '../http-service/http.service';

@Injectable({
    providedIn: 'root'
})
export class VenueService {

    baseUrlApi = ApiUrlConstants.VENUE_BASE_URL;
    httpConfig: httpConfig;

    constructor(
        private _httpService: HttpService
    ) {
    }

    /**
     * Get venue list
     * @param input
     * @returns
     */
    getVenueList(input) {
        this.httpConfig = {
            url: this.baseUrlApi,
            method: 'get',
            filterParameters: input,
            isRequestModelUsed: true,
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get single venue details
     * @param input
     * @returns
     */
    getVenueDetails(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Get the venue details and images of the selected venue
     * @param slug
     * @returns
     */
    getVenueAndImages(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/images/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }

    /**
     * Vendors who service this venue
     * @param slug
     * @returns
     */
    getVendorsOfCurrentVenue(slug) {
        this.httpConfig = {
            url: this.baseUrlApi + '/relevant-vendors/' + slug,
            method: 'get'
        }
        return this._httpService.init(this.httpConfig);
    }
}

.footer-wrap {
    background: #272338;
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #E6E6E6;
    padding: 48px 0;

    @media screen and (max-width: 1280px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 767px) {
        padding: 48px 0 32px;
    }

    .container {
        display: flex;
        gap: 99px;

        @media screen and (max-width: 1366px) {
            gap: 80px;
        }

        @media screen and (max-width: 1280px) {
            gap: 60px;
        }

        @media screen and (max-width: 1100px) {
            gap: 32px;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    .footer-links {
        .footer-links-title {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 0 16px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                margin: 0 0 8px;
                color: #E6E6E6;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #E6E6E6;
                    text-decoration: none;

                    &:hover {
                        color: #7093FF;
                    }
                }
            }
        }
    }

    .footer-left-penal {
        width: 380px;
        min-width: 380px;
        max-width: 100%;
        flex-direction: column;
        gap: 24px;
        justify-content: space-between;
        display: flex;

        @media screen and (max-width: 1100px) {
            width: 340px;
            min-width: 340px;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
            width: 380px;
            min-width: inherit;
        }

        .footer-join-box-wrap {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #F5F5F5;

            p {
                margin-bottom: 0;
                max-width: 340px;
            }

            .footer-join-box {
                position: relative;
                margin: 20px 0;
                display: flex;
                flex-wrap: wrap;

                @media screen and (max-width: 991px) {
                    margin: 10px 0 32px;
                }

                form {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                }

                .form-control {
                    border: 1px solid #272338;
                    border-radius: 16px;
                    box-shadow: none;
                    outline: none;
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #272338;
                    height: auto;
                    padding: 8px 75px 8px 18px;
                    width: 100%;

                    &::-webkit-input-placeholder {
                        color: #808080;
                    }

                    &::-moz-placeholder {
                        color: #808080;
                    }

                    &:-ms-input-placeholder {
                        color: #808080;
                    }

                    &:-moz-placeholder {
                        color: #808080;
                    }
                }

                .join-btn {
                    background: #7093FF;
                    border: 1px solid #272338;
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #FFFFFF;
                    border-radius: 0 16px 16px 0;
                    height: 40px;
                    min-width: 57px;
                    padding: 9px 10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        background: #A9BEFF;
                        color: #272338;
                    }
                }

                .error:not(.form-control) {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    margin-top: 4px;
                    //color: #D24646;
                    color: #ef4444;
                    width: 100%;
                }
            }
        }

        .footer-logo {
            a {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .footer-right-panel {
        flex: 1;
        //padding-left: 139px;
        padding-left: 99px;
        border-left: 1px solid #423B5E;

        @media screen and (max-width: 1366px) {
            padding-left: 80px;
        }

        @media screen and (max-width: 1280px) {
            padding-left: 60px;
        }

        @media screen and (max-width: 1100px) {
            padding-left: 32px;
        }

        @media screen and (max-width: 991px) {
            padding: 24px 0 0;
            border: none;
            border-top: 1px solid #423B5E;
        }

        .footer-right-panel-inner {
            display: flex;
            gap: 0 72px;

            @media screen and (max-width: 1280px) {
                gap: 0 40px;
            }

            @media screen and (max-width: 1100px) {
                gap: 0 30px;
            }

            @media screen and (max-width: 991px) {
                gap: 0 40px;
            }

            @media screen and (max-width: 575px) {
                flex-direction: column;
            }


        }

        .footer-left-links {
            @media screen and (max-width: 575px) {
                flex-wrap: wrap;
                gap: 32px 16px;
                display: flex;
            }

            .footer-links {
                margin-bottom: 48px;

                &:last-child {
                    margin-bottom: 0;
                }

                @media screen and (max-width: 1280px) {
                    margin-bottom: 40px;
                }

                @media screen and (max-width: 1100px) {
                    margin-bottom: 32px;
                }

                @media screen and (max-width: 575px) {
                    width: calc(50% - 8px);
                    margin-bottom: 0;
                }

                @media screen and (max-width: 359px) {
                    width: 100%;
                }

                .primary-btn,
                .secondary-btn {
                    width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;
                    border-color: transparent;

                    @media screen and (max-width: 575px) {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .primary-btn {
                    color: #FFFFFF;

                    &:hover {
                        border-color: #fff;
                    }
                }

                .secondary-btn {
                    color: #272338;
                }
            }
        }

        .footer-right-links {
            flex: 1;

            @media screen and (max-width: 575px) {
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid #423B5E;
            }
        }

        .footer-link-item {
            margin-bottom: 32px;
            display: flex;
            gap: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .footer-links {
                flex: 1;
            }

            @media screen and (max-width: 1280px) {
                gap: 24px;
            }

            @media screen and (max-width: 480px) {
                gap: 32px 0;
                flex-direction: column;
            }
        }

        .copy-right-info {
            width: 100%;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            color: #E6E6E6;
            text-align: right;
            text-transform: uppercase;
            margin-top: 48px;

            @media screen and (max-width: 1280px) {
                margin-top: 40px;
            }

            @media screen and (max-width: 991px) {
                text-align: left;
                margin-top: 32px;
            }

            span {
                color: #7093FF;
                @media screen and (max-width: 480px) {
                    display: block;
                }
            }

            a {
                color: #7093FF;

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }

    .footer-social-links {
        display: flex;
        gap: 14px;

        a {
            display: inline-flex;
        }
    }
}

.back-to-top {
    background: #7093FF;
    border: 1px solid #272338;
    border-radius: 16px;
    padding: 0;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 10;
    width: 40px;
    height: 40px;
    transition: all 0.35s ease 0s;

    @media screen and (max-width: 767px) {
        right: 16px;
        bottom: 80px;
        background: rgba(112, 147, 255, 0.8);
        backdrop-filter: blur(3px);
    }

    img {
        transition: all 0.35s ease 0s;
    }

    &:hover {
        background: #A9BEFF;
        color: #272338;

        img {
            filter: brightness(0);
        }
    }
}



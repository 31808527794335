<!-- Search panel -->
<div class="search-panel-wrap">
    <!-- Search by popular keywords -->
    <div class="search-by-keywords" style="display: none;">
        <div class="container">
            <div class="search-by-keywords-title">Search by popular keywords</div>
            <div class="popular-keywords">
                <button type="button" class="secondary-btn">Vineyard</button>
                <button type="button" class="secondary-btn">Vow writing guide</button>
                <button type="button" class="secondary-btn">Short wedding dresses</button>
                <button type="button" class="secondary-btn">Registry</button>
                <button type="button" class="secondary-btn">Honeymoons</button>
            </div>
        </div>
    </div>
    <!-- End Search by popular keywords -->

    <!-- Search - showing results -->
    <div class="showing-results">
        <div class="container">
            @if (searchKeyword != '') {
                Showing results for <strong>"{{ searchKeyword }}"</strong> <span title="View all results"
                                                                                 (click)="goToSearchPage(commonConstants.MODULE_ALL)"
                                                                                 class="view-all">
                @if (totalResultCount != 0) {
                    View all results
                }
            </span>
            }
        </div>
    </div>
    <!-- End Search - showing results -->

    <!-- No Search result -->
    @if (searchData != undefined && totalResultCount == 0 && searchKeyword != '') {
        <div class="search-results-not-found">
            <div class="container">
                <p class="emoji-icon">😞</p>
                <p>There were no results for <strong>'{{ searchKeyword }}'</strong></p>
                <button type="button" class="clear-search-btn" (click)="clearSearchEvent()">Clear search</button>
            </div>
        </div>
    } @else {
        <!-- Search result List -->
        <div class="search-result-list">
            <div class="container">
                @if (searchKeyword != '') {
                    @if (venuesCount > 0) {
                        <div class="search-result-box">
                            <div class="search-category">venues ({{ venuesCount }})</div>
                            @for (venue of venues; track venue; let i = $index) {
                                <div class="search-result-item">
                                    <div (click)="goToDetailPage(routesConstants.VENUE_NAVIGATION, venue.slug)"
                                         class="search-result-item-inner">
                                        <img
                                            [src]="venue?.venue_images && venue?.venue_images.length > 0 ? commonConstants.IMAGE_KIT_URL + venue.venue_images[0].imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                            width="48" height="48" alt="search-result-image">
                                        <h3>{{ venue.venueName }}</h3>
                                        <p>{{ venue?.regions?.name ? venue?.regions?.name + ', ' : '' }}
                                            {{ venue?.states?.name }}</p>
                                    </div>
                                </div>
                            }

                            <button type="button" class="secondary-btn"
                                    (click)="goToSearchPage(commonConstants.MODULE_VENUES)">View
                                all venue results
                                <img src="assets/images/button-right-icon.svg" width="12" height="9"
                                     alt="button-right-icon"></button>

                        </div>
                    }

                    @if (vendorsCount > 0) {
                        <div class="search-result-box">
                            <div class="search-category">vendors ({{ vendorsCount }})</div>
                            @for (vendor of vendors; track vendor; let j = $index) {
                                <div class="search-result-item">
                                    <div (click)="goToDetailPage(routesConstants.VENDOR_NAVIGATION, vendor.slug)"
                                         class="search-result-item-inner">
                                        <img
                                            [src]="vendor?.vendor_images && vendor?.vendor_images.length > 0 ? commonConstants.IMAGE_KIT_URL + vendor.vendor_images[0].imagePath : commonConstants.DEFAULT_NO_IMAGE"
                                            width="48" height="48" alt="search-result-image">
                                        <h3>{{ vendor.serviceName }}</h3>
                                        <p>{{ vendor?.regions?.name ? vendor?.regions?.name + ' · ' : '' }}
                                            {{
                                                vendor?.vendor_types && vendor?.vendor_types.length > 0 ? vendor?.vendor_types[0].name :
                                                    ''
                                            }}
                                        </p>
                                    </div>
                                </div>
                            }

                            <button type="button" class="secondary-btn"
                                    (click)="goToSearchPage(commonConstants.MODULE_VENDORS)">View all vendor results
                                <img src="assets/images/button-right-icon.svg" width="12" height="9"
                                     alt="button-right-icon"></button>
                        </div>
                    }

                    @if (blogsCount > 0) {
                        <div class="search-result-box">
                            <div class="search-category">articles ({{ blogsCount }})</div>

                            @for (blog of blogs; track blog; let j = $index) {
                                <div class="search-result-item">
                                    <div
                                        (click)="goToBlogDetailPage(routesConstants.BLOG,blog?.blog_categories[0].slug, blog.slug)"
                                        class="search-result-item-inner">
                                        <img
                                            [src]="blog.featureImagePath ? commonConstants.IMAGE_KIT_URL + blog.featureImagePath : commonConstants.DEFAULT_NO_IMAGE"
                                            width="48" height="48" alt="search-result-image">
                                        <p>{{
                                                blog?.blog_categories && blog?.blog_categories.length > 0 ? blog?.blog_categories[0].name :
                                                    ''
                                            }}</p>
                                        <h3>{{ blog.title }}</h3>
                                    </div>
                                </div>
                            }

                            <button type="button" class="secondary-btn"
                                    (click)="goToSearchPage(commonConstants.MODULE_ARTICLES)">View all article
                                results <img src="assets/images/button-right-icon.svg" width="12" height="9"
                                             alt="button-right-icon"></button>
                        </div>
                    }
                }
            </div>
        </div>
    }
    <!-- End Search result List  -->

    <!-- Close search Button -->
    <div class="close-search-btn" title="Close search" (click)="closeSearchEvent()">Close search</div>
</div>
